import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ERRORS from '../../constants/error';
import APPCONSTANTS, { ROLE_LABELS } from '../../constants/appConstants';
import DetailCard from '../../components/detailCard/DetailCard';
import CustomTable from '../../components/customtable/CustomTable';
import toastCenter from '../../utils/toastCenter';
import Loader from '../../components/loader/Loader';
import { changeAdminUserRoleRequest, fetchUserRolesListRequest } from '../../store/userRoles/actions';
import { fetchRegionsRequest } from '../../store/region/actions';
import {
  getUserRolesListCountSelector,
  getUserRolesListSelector,
  getUserRolesLoadingSelector
} from '../../store/userRoles/selectors';
import { IRegion } from '../../store/region/types';
import { getRegionsSelector, getRegionsLoadingSelector } from '../../store/region/selectors';
import Modal from '../../components/modal/ModalForm';
import { IUserRolesList } from '../../store/userRoles/types';
import UserAssignIcon from '../../assets/images/user-role-not-assigned.svg';
import UserRemoveIcon from '../../assets/images/user-role-assigned.svg';
import QuickSightUserIcon from '../../assets/images/quickSightUser.svg';
import AssignQuickSightIcon from '../../assets/images/assignQuickSightUser.svg';
import { checkSelectedValueChanged, IOption } from '../../components/mulitiSelect/MultiSelect';
import { differenceOfArray } from '../../utils/commonUtils';
import RenderSiteAdminRoleModal from './SiteAdminRoleModal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './UserRoles.scss';
import Checkbox from '../../components/formComponent/Checkbox';

interface IMatchParams {
  regionId: string;
}

const UserRoles = (): React.ReactElement => {
  const [listParams, setListReqParams] = useState({
    page: 1,
    rowsPerPage: APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE,
    searchTerm: ''
  });
  const [assignUserRole, setAssignUserRole] = useState<{
    open: boolean;
    selectedUser: IUserRolesList;
  }>({
    open: false,
    selectedUser: {} as IUserRolesList
  });
  const [assignUserQuickSight, setAssignUserQuickSight] = useState<{
    open: boolean;
    selectedUser: IUserRolesList;
  }>({
    open: false,
    selectedUser: {} as IUserRolesList
  });
  const [selectedValue, setSelectedValue] = useState<IOption[]>([]);
  const dispatch = useDispatch();
  const userRolesList = useSelector(getUserRolesListSelector);
  const loading = useSelector(getUserRolesLoadingSelector);
  const userRolesListCount = useSelector(getUserRolesListCountSelector);
  const regionLoading = useSelector(getRegionsLoadingSelector);
  const region = useSelector(getRegionsSelector);
  const [regionDropDown, setRegionDropDown] = useState<IRegion[]>([]);
  const [regionSelect, setRegionSelect] = useState<IRegion | null>(null);
  const [isDefaultSiteRemoved, setDefaultSiteRemoved] = useState(false);
  const [isEmrUserSiteAdminTab, setIsEmrUserSiteAdminTab] = useState(false);
  const spiceQsRef = useRef<HTMLInputElement>(null);
  const tcQsRef = useRef<HTMLInputElement>(null);
  const [spiceDashboardAccessChecked, setSpiceDashboardAccessChecked] = useState(false);
  const [tcDashboardAccessChecked, setTcDashboardAccessChecked] = useState(false);

  const { regionId } = useParams<IMatchParams>();

  const multiSelectRef = useRef<any>();
  const initiallySelectedRef = useRef<any>();

  useEffect(() => {
    if (region.length && regionSelect) {
      dispatch(
        fetchUserRolesListRequest({
          skip: (listParams.page - 1) * listParams.rowsPerPage,
          limit: listParams.rowsPerPage,
          search: listParams.searchTerm,
          country: regionSelect?.id,
          failureCb: (e) => {
            if (e.message === ERRORS.NETWORK_ERROR.message) {
              toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
            } else {
              toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.USER_ROLES_LIST_FETCH_ERROR);
            }
          }
        })
      );
    }
  }, [listParams, regionId, dispatch, region, regionSelect]);

  useEffect(() => {
    if (!region.length) {
      dispatch(
        fetchRegionsRequest({
          skip: APPCONSTANTS.NUMBER.ZERO,
          limit: null
        })
      );
    }
    const sortedRegion = [...region].sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    setRegionDropDown(sortedRegion);
    setRegionSelect(sortedRegion[0]);
  }, [region, dispatch]);

  const handleSearch = (searchString: string) => {
    setListReqParams((prevstate) => {
      return {
        ...prevstate,
        page: 1,
        searchTerm: searchString
      };
    });
  };

  const handlePage = (pageNo: number, rowsPerPage: number | undefined = APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE) => {
    setListReqParams((prevstate) => {
      return {
        ...prevstate,
        page: pageNo,
        rowsPerPage
      };
    });
  };

  const handleRowClick = (e: any, data: any) => {
    setAssignUserRole({
      open: true,
      selectedUser: data
    });
  };

  const handleQuickSightRowClick = (e: any, data: any) => {
    setAssignUserQuickSight({
      open: true,
      selectedUser: data
    });
  };

  const closeAssignUserRoleModal = () => {
    setIsEmrUserSiteAdminTab(false);
    setSelectedValue([]);
    setAssignUserRole({
      open: false,
      selectedUser: {} as IUserRolesList
    });
    setDefaultSiteRemoved(false);
  };

  const closeAssignUserQuickSight = () => {
    setSpiceDashboardAccessChecked(false);
    setTcDashboardAccessChecked(false);
    setSelectedValue([]);
    setAssignUserQuickSight({
      open: false,
      selectedUser: {} as IUserRolesList
    });
    setDefaultSiteRemoved(false);
  };

  const userRoleUpdateDispatch = () => {
    const userRole = assignUserRole.selectedUser?.role;
    const data: any = {};
    data.id = assignUserRole.selectedUser?.id;
    if (
      assignUserRole.selectedUser?.isAssigned &&
      !assignUserRole.selectedUser?.isEmrUserSiteAdmin &&
      userRole !== APPCONSTANTS.ROLES.PEER_SUPERVISOR
    ) {
      data.isRoleRemoved = true;
    } else {
      if (initiallySelectedRef.current) {
        const initialValue = initiallySelectedRef?.current.map((item: IOption) => item?.value);
        const changedValue = selectedValue.map((item: IOption) => item?.value);
        const addedSites = differenceOfArray(changedValue, initialValue);
        const removedSites = differenceOfArray(initialValue, changedValue);
        data.siteTenants = addedSites;
        data.removedSites = removedSites;
        initiallySelectedRef.current = null;
      }
    }
    dispatch(
      changeAdminUserRoleRequest({
        data,
        successCb: () => {
          closeAssignUserRoleModal();
          handlePage(1);
          toastCenter.success(APPCONSTANTS.SUCCESS, APPCONSTANTS.USER_ASSIGN_ROLE_SUCCESS);
        },
        failureCb: (e) => {
          toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.USER_ASSIGN_ROLE_ERROR);
        }
      })
    );
  };

  const onSubmitAssignUserQuickSight = () => {
    const user = assignUserQuickSight.selectedUser;
    const data: any = {
      id: user.id,
      username: user.email,
      isQuicksightSpiceRoleAssign: !user?.isQuicksightSpiceUser && spiceQsRef.current?.checked,
      isQuicksightSpiceRoleRemove: user?.isQuicksightSpiceUser && !spiceQsRef.current?.checked,
      isQuicksightTcRoleAssign: !user?.isQuicksightTcUser && tcQsRef.current?.checked,
      isQuicksightTcRoleRemove: user?.isQuicksightTcUser && !tcQsRef.current?.checked
    };
    dispatch(
      changeAdminUserRoleRequest({
        data,
        successCb: () => {
          closeAssignUserQuickSight();
          handlePage(1);
          toastCenter.success(APPCONSTANTS.SUCCESS, APPCONSTANTS.USER_QUICKSIGHT_SUCCESS);
        },
        failureCb: (e: any) => {
          toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.USER_QUICKSIGHT_ERROR);
        }
      })
    );
  };

  const checkIsSubmitBtnDisabled = () => {
    const isSiteAdmin = assignUserRole.selectedUser?.role === APPCONSTANTS.ROLES.PEER_SUPERVISOR;
    if (
      isSiteAdmin &&
      initiallySelectedRef?.current &&
      selectedValue &&
      checkSelectedValueChanged(initiallySelectedRef?.current, selectedValue)
    ) {
      return true;
    } else if (isEmrUserSiteAdminTab && checkSelectedValueChanged(initiallySelectedRef?.current, selectedValue)) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmitAssignUserRoleModal = () => {
    userRoleUpdateDispatch();
  };

  const getNewUserRole = (role: string) => {
    return ROLE_LABELS[role] || '';
  };

  const renderAssignUserModal = () => {
    const userRole = assignUserRole.selectedUser?.role;
    if (userRole !== APPCONSTANTS.ROLES.PEER_SUPERVISOR) {
      const newRole = getNewUserRole(userRole);
      const confirmationMessage =
        assignUserRole.selectedUser?.isAssigned && !assignUserRole.selectedUser?.isEmrUserSiteAdmin
          ? `Do you want to remove ${newRole} Role for ${assignUserRole.selectedUser.name}?`
          : `Assign ${newRole} Role to ${assignUserRole.selectedUser.name} under ${assignUserRole.selectedUser.org_name} ${assignUserRole.selectedUser.application_name}?`;
      const isEmrUserSiteAdmin = assignUserRole.selectedUser?.isEmrUserSiteAdmin;
      if (!assignUserRole.selectedUser?.isAssigned || isEmrUserSiteAdmin) {
        return (
          <>
            {!(assignUserRole.selectedUser?.isAssigned && assignUserRole.selectedUser?.isEmrUserSiteAdmin) ? (
              <Tabs>
                <TabList>
                  <Tab onClick={() => setIsEmrUserSiteAdminTab(false)}>{newRole + ' Access'}</Tab>
                  <Tab onClick={() => setIsEmrUserSiteAdminTab(true)}>Site Specific Access</Tab>
                </TabList>
                <TabPanel>{confirmationMessage}</TabPanel>
                <TabPanel>
                  <RenderSiteAdminRoleModal
                    initiallySelectedRef={initiallySelectedRef}
                    multiSelectRef={multiSelectRef}
                    assignUserRole={assignUserRole}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    isEmrUserSiteAdmin={true}
                    isDefaultSiteRemoved={isDefaultSiteRemoved}
                    setDefaultSiteRemoved={setDefaultSiteRemoved}
                  />
                </TabPanel>
              </Tabs>
            ) : (
              <RenderSiteAdminRoleModal
                initiallySelectedRef={initiallySelectedRef}
                multiSelectRef={multiSelectRef}
                assignUserRole={assignUserRole}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                isEmrUserSiteAdmin={true}
                isDefaultSiteRemoved={isDefaultSiteRemoved}
                setDefaultSiteRemoved={setDefaultSiteRemoved}
              />
            )}
          </>
        );
      } else { return <>{confirmationMessage}</>; }
    } else {
      return (
        <RenderSiteAdminRoleModal
          initiallySelectedRef={initiallySelectedRef}
          multiSelectRef={multiSelectRef}
          assignUserRole={assignUserRole}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          isDefaultSiteRemoved={isDefaultSiteRemoved}
          setDefaultSiteRemoved={setDefaultSiteRemoved}
        />
      );
    }
  };
  useEffect(() => {
    if (assignUserQuickSight.selectedUser?.isQuicksightSpiceUser) {
      setSpiceDashboardAccessChecked(true);
    }
    if (assignUserQuickSight.selectedUser?.isQuicksightTcUser) {
      setTcDashboardAccessChecked(true);
    }
  }, [
    assignUserQuickSight.open,
    assignUserQuickSight.selectedUser?.isQuicksightSpiceUser,
    assignUserQuickSight.selectedUser?.isQuicksightTcUser
  ]);

  const renderAssignUserQuickSight = useCallback(() => {
    const confirmationMessage = assignUserQuickSight.selectedUser?.isQuicksightSpiceUser
      ? `Please select the Quicksight Dashboard access role(s) for ${assignUserQuickSight.selectedUser.name}.`
      : `Assign QuickSight permission to ${assignUserQuickSight.selectedUser.name}?`;
    return (
      <>
        {confirmationMessage}
        <br />
        <br />
        <div className='row'>
          <div className='col-auto'>
            <Checkbox
              checked={spiceDashboardAccessChecked}
              customRef={spiceQsRef}
              disabled={Boolean(tcDashboardAccessChecked)}
              label={APPCONSTANTS.QUICKSIGHT_DASHBOARD.SPICE}
              onChange={(e) => {
                setSpiceDashboardAccessChecked(e.target.checked);
              }}
            />
          </div>
          <div className='col-auto'>
            <Checkbox
              checked={tcDashboardAccessChecked}
              label={APPCONSTANTS.QUICKSIGHT_DASHBOARD.SPICE_ENGAGE}
              customRef={tcQsRef}
              onChange={(e) => {
                if (e.target.checked) {
                  setSpiceDashboardAccessChecked(true);
                }
                setTcDashboardAccessChecked(e.target.checked);
              }}
            />
          </div>
        </div>
      </>
    );
  }, [
    assignUserQuickSight.selectedUser?.isQuicksightSpiceUser,
    assignUserQuickSight.selectedUser.name,
    spiceDashboardAccessChecked,
    tcDashboardAccessChecked
  ]);

  const getSubmitBtnText = () => {
    const isSiteAdmin = assignUserRole.selectedUser?.role === APPCONSTANTS.ROLES.PEER_SUPERVISOR;
    if (
      (assignUserRole.selectedUser?.isAssigned && !isSiteAdmin && !assignUserRole.selectedUser?.isEmrUserSiteAdmin) ||
      (isSiteAdmin && isDefaultSiteRemoved)
    ) {
      return 'Remove';
    } else {
      return 'Assign';
    }
  };

  const getQuickSightSubmitBtnText = () => {
    return assignUserQuickSight.selectedUser?.isQuicksightSpiceUser ? 'Save' : 'Assign';
  };

  const getTitleText = () => {
    if (
      assignUserRole.selectedUser?.isAssigned &&
      assignUserRole.selectedUser?.role !== APPCONSTANTS.ROLES.PEER_SUPERVISOR &&
      !assignUserRole.selectedUser?.isEmrUserSiteAdmin
    ) {
      return `Removing role access for ${assignUserRole.selectedUser.name}`;
    } else {
      return `Manage role access to ${assignUserRole.selectedUser.name}`;
    }
  };

  const getQuickSightTitleText = () => {
    if (assignUserQuickSight.selectedUser?.isQuicksightSpiceUser) {
      return `Manage QuickSight access for ${assignUserQuickSight.selectedUser.name}`;
    } else {
      return `Assigning QuickSight access to ${assignUserQuickSight.selectedUser.name}`;
    }
  };

  return (
    <>
      {(regionLoading || loading) && <Loader />}
      {
        <>
          <div className='col-12'>
            <DetailCard
              header='Users'
              isSearch={true}
              onSearch={handleSearch}
              searchPlaceholderName='Search Email'
              selectBox={true}
              selectBoxProps={{
                options: regionDropDown,
                loading: regionLoading,
                value: regionSelect,
                onChange: (e: any) => {
                  if (e.id !== regionSelect?.id) {
                    setRegionSelect(e);
                    setListReqParams({
                      page: 1,
                      rowsPerPage: APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE,
                      searchTerm: listParams.searchTerm
                    });
                  }
                }
              }}
            >
              <CustomTable
                rowData={userRolesList}
                columnsDef={[
                  {
                    id: 1,
                    name: 'name',
                    label: 'Name'
                  },
                  {
                    id: 2,
                    name: 'email',
                    label: 'Email'
                  },
                  {
                    id: 3,
                    name: 'application_name',
                    label: 'Role Level'
                  }
                ]}
                isDelete={false}
                isEdit={false}
                isView={false}
                isDownload={false}
                page={listParams.page}
                rowsPerPage={listParams.rowsPerPage}
                count={userRolesListCount}
                handlePageChange={handlePage}
                isCustom={true}
                customProps={[
                  {
                    title: '',
                    icon: '',
                    style: { width: 18 },
                    type: (data: any) => (data?.isAssigned ? 'success' : ''),
                    onClick: handleRowClick,
                    iconFormatter: (data: any) => (data?.isAssigned ? UserRemoveIcon : UserAssignIcon),
                    tooltipFormatter: (data: any) => (data?.isAssigned ? 'Manage Role' : 'Assign Role')
                  },
                  {
                    title: '',
                    icon: '',
                    style: { width: 25 },
                    type: (data: any) => (data?.isQuicksightSpiceUser ? 'success' : ''),
                    onClick: handleQuickSightRowClick,
                    iconFormatter: (data: any) =>
                      data?.isQuicksightSpiceUser ? AssignQuickSightIcon : QuickSightUserIcon,
                    tooltipFormatter: (data: any) =>
                      data?.isQuicksightSpiceUser ? 'Manage QuickSight Role' : 'Assign QuickSight Role'
                  }
                ]}
              />
            </DetailCard>
          </div>
          {assignUserRole.open && (
            <Modal
              show={assignUserRole.open}
              title={getTitleText()}
              cancelText='Cancel'
              isDisabled={checkIsSubmitBtnDisabled()}
              submitText={getSubmitBtnText()}
              autoHeight={true}
              handleCancel={closeAssignUserRoleModal}
              handleFormSubmit={onSubmitAssignUserRoleModal}
              render={renderAssignUserModal}
            />
          )}
          {assignUserQuickSight.open && (
            <Modal
              show={assignUserQuickSight.open}
              title={getQuickSightTitleText()}
              cancelText='Cancel'
              isDisabled={!assignUserQuickSight.selectedUser?.isQuicksightSpiceUser && !spiceDashboardAccessChecked}
              submitText={getQuickSightSubmitBtnText()}
              autoHeight={true}
              handleCancel={closeAssignUserQuickSight}
              handleFormSubmit={onSubmitAssignUserQuickSight}
              render={renderAssignUserQuickSight}
            />
          )}
        </>
      }
    </>
  );
};

export default UserRoles;
