import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as siteListService from '../../services/siteAPI';
import { IFetchSiteListRequest } from '../site/types';
import { fetchSiteListSuccess, fetchSiteListFailure } from './actions';
import { FETCH_SITE_LIST_REQUEST } from './actionTypes';

/*
  Worker Saga: Fired on FETCH_REGIONS_REQUEST action
*/
export function* fetchSiteList({
  regionId,
  skip,
  limit,
  search,
  userBased,
  failureCb
}: IFetchSiteListRequest): SagaIterator {
  try {
    const {
      data: { entityList: sites, totalCount: total }
    } = yield call(siteListService.fetchSiteList as any, { regionId, limit, skip, search, userBased });
    const payload = { sites, total, limit };
    yield put(fetchSiteListSuccess(payload));
  } catch (e) {
    if (e instanceof Error) {
      failureCb?.(e);
      yield put(fetchSiteListFailure(e));
    }
  }
}

/*
  Starts worker saga on latest dispatched specific action.
  Allows concurrent increments.
*/
function* siteSaga() {
  yield all([takeLatest(FETCH_SITE_LIST_REQUEST, fetchSiteList)]);
}

export default siteSaga;
