import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import { getHomeUrlByRole, PROTECTED_ROUTES } from './constants/route';
import SiteList from './containers/site/SiteList';
import ReportList from './containers/report/ReportList';
import { getIsLoggedInSelector, roleSelector, initializingSelector, userDataSelector } from './store/user/selectors';
import { AppLayout } from './components/appLayout/AppLayout';
import APPCONSTANTS from './constants/appConstants';
import UserRoles from './containers/userRoles/UserRoles';
import HFReport from './containers/report/HFReport';
import Loader from './components/loader/Loader';
import { routeToParentApp } from './utils/routeUtils';

interface IRoute {
  path: string;
  exact: boolean;
  component: React.FunctionComponent<any> | React.ComponentClass<any>;
}

interface IProtectedRoute extends IRoute {
  authorisedRoles?: string[];
}

const protectedRoutes: IProtectedRoute[] = (() => {
  const { REPORT_ADMIN, FACILITY_REPORT_ADMIN } = APPCONSTANTS.ROLES;
  return [
    {
      path: PROTECTED_ROUTES.siteByRegion,
      exact: true,
      component: SiteList,
      authorisedRoles: [REPORT_ADMIN, FACILITY_REPORT_ADMIN]
    },
    {
      path: PROTECTED_ROUTES.reportList,
      exact: true,
      component: ReportList,
      authorisedRoles: [REPORT_ADMIN, FACILITY_REPORT_ADMIN]
    },
    {
      path: PROTECTED_ROUTES.HFReport,
      exact: true,
      component: HFReport,
      authorisedRoles: [REPORT_ADMIN, FACILITY_REPORT_ADMIN]
    },
    {
      path: PROTECTED_ROUTES.manageUserRoles,
      exact: true,
      component: UserRoles,
      authorisedRoles: [REPORT_ADMIN, FACILITY_REPORT_ADMIN]
    }
  ];
})();

export const AppRoutes = () => {
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const role = useSelector(roleSelector);
  const initializingApp = useSelector(initializingSelector);
  const userData = useSelector(userDataSelector);

  const HomeUrl = useMemo(() => getHomeUrlByRole(role, userData), [role, userData]);
  useEffect(() => {
    if (!isLoggedIn && !initializingApp) {
      routeToParentApp();
    }
  }, [isLoggedIn, initializingApp]);

  return isLoggedIn ? (
    <AppLayout>
      <Switch>
        {protectedRoutes.map((route: any) =>
          route.authorisedRoles?.includes(role) ? (
            <Route
              path={route.path}
              exact={route.exact}
              key={route.path}
              render={(routeProps: any) => <route.component key={routeProps.location.key} {...routeProps} />}
            />
          ) : null
        )}
        <Redirect exact={true} to={HomeUrl} />
      </Switch>
    </AppLayout>
  ) : (
    <Loader />
  );
};
