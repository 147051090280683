import * as SITE_LIST_TYPES from './actionTypes';
import {
  IFetchSiteListFailure,
  IFetchSiteListRequest,
  IFetchSiteListSuccess,
  IFetchSiteListSuccessPayload,
  ISiteDetail,
  ISetSiteDetail,
  IClearSiteDetail
} from '../site/types';

export const fetchSiteListRequest = ({
  regionId,
  skip,
  limit,
  search,
  userBased,
  failureCb
}: {
  regionId: number;
  skip: number;
  limit: number | null;
  search?: string;
  userBased?: boolean;
  failureCb?: (error: Error) => void;
}): IFetchSiteListRequest => ({
  type: SITE_LIST_TYPES.FETCH_SITE_LIST_REQUEST,
  skip,
  limit,
  regionId,
  search,
  userBased,
  failureCb
});

export const fetchSiteListSuccess = (payload: IFetchSiteListSuccessPayload): IFetchSiteListSuccess => ({
  type: SITE_LIST_TYPES.FETCH_SITE_LIST_SUCCESS,
  payload
});

export const fetchSiteListFailure = (error: Error): IFetchSiteListFailure => ({
  type: SITE_LIST_TYPES.FETCH_SITE_LIST_FAILURE,
  error
});

export const setSiteDetail = (payload: Partial<ISiteDetail>): ISetSiteDetail => ({
  type: SITE_LIST_TYPES.SET_SITE_DETAIL,
  payload
});

export const clearSiteDetail = (): IClearSiteDetail => ({
  type: SITE_LIST_TYPES.CLEAR_SITE_DETAIL
});
