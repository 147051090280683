import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import HomeIcon from '../../assets/images/home.svg';
import { PROTECTED_ROUTES } from '../../constants/route';
import { useDispatch, useSelector } from 'react-redux';
import { getRegionDetailSelector } from '../../store/region/selectors';
import { getSiteDetailSelector } from '../../store/site/selectors';

import styles from './Breadcrumb.module.scss';
import { clearRegionDetail } from '../../store/region/actions';
import { fetchCountryName } from '../../store/user/actions';
import { isUserAdmin, parseURLData, reportNameFormat } from '../../utils/commonUtils';
import { roleSelector, userSuiteAccessSelector } from '../../store/user/selectors';

export interface ICustomBreadcrumb {
  label: string;
  route: string;
  active: boolean;
}

const defaultBreadcrumbs: ICustomBreadcrumb[] = [
  { route: PROTECTED_ROUTES.manageUserRoles, label: 'Manage User Roles', active: true }
];

const classNameStyle = `fs-1dot5 fw-bold no-pointer-events ${styles.active}`;
/**
 * Dynamic breadcrumb for application
 * @returns {React.ReactElement}
 */
const Breadcrumb = ({ custom }: { custom?: ICustomBreadcrumb[] }): React.ReactElement => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const region = useSelector(getRegionDetailSelector);
  const site = useSelector(getSiteDetailSelector);
  const userSuiteAccess = useSelector(userSuiteAccessSelector);
  const { countryId = '0', regionName = '', siteId, siteName, reportType } = parseURLData(pathname);
  const role = useSelector(roleSelector);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customBreadcrumb = custom?.length ? custom : [];

  const activeRoute = useMemo(
    () =>
      Object.values(PROTECTED_ROUTES).find((route) => Boolean(matchPath(pathname, { path: route, exact: true }))) ?? '',
    [pathname]
  );

  const handleHome = () => {
    dispatch(clearRegionDetail());
    dispatch(fetchCountryName());
  };

  const defaultBreadcrumb = defaultBreadcrumbs.find(({ route }) =>
    Boolean(matchPath(pathname, { path: route, exact: true }))
  );

  const activeRouteConditions = (
    result: any,
    pageType: {
      isHFReport: boolean;
    },
    reportName: string
  ) => {
    if (pageType.isHFReport) {
      result.push({
        label: reportNameFormat(reportName),
        route: PROTECTED_ROUTES.HFReport.replace(':regionId', countryId ?? ' ')
          .replace(':regionName', regionName ?? ' ')
          .replace(':siteId', siteId ?? ' ')
          .replace(':siteName', siteName ?? ' '),
        active: activeRoute.includes(':siteId')
      });
    }
  };

  const sections = useMemo(
    (customSections: ICustomBreadcrumb[] = customBreadcrumb) => {
      const result: ICustomBreadcrumb[] = [];
      const showSite = activeRoute.includes(':siteId');
      // we have a default breadcrumb for certain routes
      if (defaultBreadcrumb) {
        // this if block executes when current route is one of defaultBreadcrumbs routes
        result.push(defaultBreadcrumb);
        return result;
      }
      if (isUserAdmin(role) && activeRoute.includes(':regionId')) {
        result.push({
          label: regionName ?? ' ',
          route: PROTECTED_ROUTES.siteByRegion
            .replace(':regionId', countryId ?? ' ')
            .replace(':regionName', regionName ?? ' '),
          active: activeRoute.includes(':regionId') && !activeRoute.includes(':siteId') && !customSections.length
        });
      }
      if ((site?.name.replace(/[/\s]/g, '') && showSite) || (siteName && showSite)) {
        const isHFReport = Boolean(matchPath(pathname, { path: PROTECTED_ROUTES.HFReport, exact: true }));
        result.push({
          label: siteName ?? ' ',
          route: PROTECTED_ROUTES.reportList
            .replace(':regionId', countryId ?? ' ')
            .replace(':regionName', regionName ?? ' ')
            .replace(':siteId', siteId ?? ' ')
            .replace(':siteName', site.name.replace(/\//g, '') || siteName || ' '),
          active: !isHFReport && activeRoute.includes(':siteId')
        });
        activeRouteConditions(
          result,
          {
            isHFReport
          },
          reportType
        );
      }
      if (customSections?.length) {
        customSections.forEach((section) => {
          result.push({
            label: section.label,
            route: section.route,
            active: section.active
          });
        });
      }
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [region, site, pathname, activeRoute, customBreadcrumb, defaultBreadcrumb, countryId]
  );

  return (
    <div className={`${styles.breadcrumb} d-flex align-items-center`}>
      {userSuiteAccess?.length > 1 ? (
        <a
          href={process.env.REACT_APP_SPICE_WEB_URL}
          className={`${styles.homeIcon} d-inline-flex align-items-center justify-content-center me-dot75 lh-0`}
        >
          <img className='d-inline-block' src={HomeIcon} alt='Home' />
        </a>
      ) : (
        <Link
          className={`${styles.homeIcon} d-inline-flex align-items-center justify-content-center me-dot75 lh-0`}
          to={PROTECTED_ROUTES.home}
          onClick={() => {
            handleHome();
          }}
        >
          <img className='d-inline-block' src={HomeIcon} alt='Home' />
        </Link>
      )}
      <div>
        {sections.length ? (
          sections.map(({ label, route, active }, i) => (
            <React.Fragment key={label}>
              {!!i && <span className='subtle-color mx-dot25 align-baseline'>/</span>}
              <Link to={route} className={`align-baseline ${styles.breadcrumbLink} ${active ? classNameStyle : ''}`}>
                {label}
              </Link>
            </React.Fragment>
          ))
        ) : (
          <span className='fs-1dot5 primary-title fw-bold'>Home</span>
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
