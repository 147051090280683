import axios from 'axios';

export const logout = () =>
  axios({
    method: 'GET',
    url: 'auth-service/logout'
  });

export const fetchTimezoneList = () =>
  axios({
    method: 'GET',
    url: '/forms/timezone?$limit=null'
  });

export const fetchUserRoles = (token: string) =>
  axios({
    method: 'get',
    url: `user-service/user/get-user-roles?$token=${token}`
  });

export const fetchLoggedInUser = () =>
  axios({
    method: 'POST',
    url: `user-service/user/profile`
  });

export const getDefaultRoles = () =>
  axios({
    method: 'get',
    url: 'user-service/user/roles'
  });
