/**
 *
 * This method used for go to specific application based on url
 *
 * @param {string} url The URL used for go to specific website.
 */
export const goToUrl = (url: any = '/') => {
    window.location.replace(url);
};

/**
 * This method used for route our application into its parent application - SPICE.
 */
export const routeToParentApp = (isFromLogout = false) => {
    const parentAppUrl =
        `${process.env.REACT_APP_SPICE_WEB_URL}${isFromLogout ? '' : `next=${window.location.origin}`}`;
    goToUrl(parentAppUrl);
};
