import Spreadsheet from 'x-data-spreadsheet';
import { useEffect, useRef, useState } from 'react';
import XLSX, { WorkBook } from 'xlsx';
import APPCONSTANTS, { colorToCellStyleMap, REPORT_STYLES } from '../../constants/appConstants';
import 'x-data-spreadsheet/dist/xspreadsheet.css';
import { reportSizeFormat } from '../../utils/commonUtils';

interface ISpreadSheetViewerProps {
  isEdit?: boolean;
  data: any;
  className?: string;
  isMultiSheet?: boolean;
  reportName?: string;
  colWidth?: number;
  reportSize?: any;
}

const reportList = [
  APPCONSTANTS.REPORT_LIST.HF1_REPORT,
  APPCONSTANTS.REPORT_LIST.HF2_REPORT,
  APPCONSTANTS.REPORT_LIST.HF3_REPORT,
  APPCONSTANTS.REPORT_LIST.HF4_REPORT
];

const letters = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

const getColumnName = (index: number) => {
  let columnName = '';
  while (index > 0) {
    const remainder = (index - 1) % 26;
    columnName = letters[remainder] + columnName;
    index = Math.floor((index - 1) / 26);
  }
  return columnName;
};

const getStyles = (reportName: string) => {
  switch (reportName) {
    case APPCONSTANTS.REPORT_LIST.HF1_REPORT:
    case APPCONSTANTS.REPORT_LIST.HF2_REPORT:
    case APPCONSTANTS.REPORT_LIST.HF3_REPORT:
    case APPCONSTANTS.REPORT_LIST.HF4_REPORT:
      return REPORT_STYLES.HF_REPORT;
    default:
      return {};
  }
};

const getCellMerges = (merges: any[], cellIndex: any, rowIndex: any) => {
  let cellMerges: any;
  const merge = merges.find((item: any) => item.s.c === cellIndex && item.s.r === rowIndex);
  if (merge) {
    cellMerges = [merge.e.r - merge.s.r, merge.e.c - merge.s.c];
  }
  return cellMerges;
};

const getCellStyle = (reportName: string, workbookSheet: any = null, cellIndex: any, rowIndex: any) => {
  let cellStyle = 0;
  const style = workbookSheet[`${letters[cellIndex]}${[rowIndex + 1]}`]?.s;
  if (style) {
    if (reportList.includes(reportName)) {
      if (style.patternType && style.fgColor?.rgb) {
        const rgbValue = style.fgColor.rgb;
        cellStyle = colorToCellStyleMap[rgbValue] || 1;
      } else {
        cellStyle = 2;
      }
    }
  }
  return cellStyle;
};
const calculateColumnWidths = (sheetData: any, reportSize: any) => {
  // Initialize an array to store maximum widths for each column
  const colWidths: number[] = [];

  // Loop through each row of data
  sheetData.forEach((row: any) => {
    row.forEach((cell: any, colIndex: number) => {
      const cellLength = cell ? cell.toString().length : 0;
      // If the column index doesn't have a value yet, initialize it
      if (!colWidths[colIndex]) {
        colWidths[colIndex] = 0;
      }
      // Update the max width for the current column
      colWidths[colIndex] = Math.max(colWidths[colIndex], cellLength);
    });
  });
  // Return the calculated widths without any mapping
  if (reportSize.index.length > 0) {
    reportSize.index.forEach((data: any) => {
      const key: number = Number(Object.keys(data)[0]);
      const value = data[key];
      colWidths[key] = value;
    });
  }

  const characterToPixel = (charLength: number) => charLength * reportSize.charPixel; // Assuming 7pixels per character
  const columnWidthsInPixels = colWidths.map((length) => characterToPixel(length));
  return columnWidthsInPixels; // Raw widths based on cell lengths
};

const formatData = (workbook: WorkBook, reportName: string, reportSize: any) => {
  const formattedData: any = [];
  workbook.SheetNames.forEach((name: any) => {
    const sheet: any = { name, rows: {}, merges: [], ref: '' };
    const workbookSheet = workbook.Sheets[name];
    const sheetData = XLSX.utils.sheet_to_json(workbookSheet, { raw: false, header: 1 });
    const colWidths = calculateColumnWidths(sheetData, reportSize);
    sheetData.forEach((row: any, rowIndex: any) => {
      const cells: any = {};
      const rowdata = [...row];
      rowdata.forEach((cell: any, cellIndex: any) => {
        const merge = getCellMerges(workbookSheet['!merges'] || [], cellIndex, rowIndex);
        const style = getCellStyle(reportName, workbookSheet, cellIndex, rowIndex);
        cells[cellIndex] = {
          text: cell,
          ...(merge ? { merge } : {}),
          ...(style ? { style } : {})
        };
      });
      sheet.rows[rowIndex] = { cells };
    });
    sheet.cols = colWidths.map((width) => ({ width }));
    sheet.merges = (workbookSheet['!merges'] || []).map((merge: any) => {
      const startCellCol = getColumnName(merge.s.c + 1);
      const endCellCol = getColumnName(merge.e.c + 1);
      return `${startCellCol}${merge.s.r + 1}:${endCellCol}${merge.e.r + 1}`;
    });
    sheet.styles = getStyles(reportName);
    formattedData.push(sheet);
  });
  return formattedData;
};

const SpreadsheetViewer = ({
  data,
  isEdit = false,
  className = '',
  isMultiSheet = true,
  reportName = '',
  colWidth,
  reportSize
}: ISpreadSheetViewerProps) => {
  const spreadSheetContainer = useRef<HTMLDivElement>(null);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const workbook = XLSX.read(data, { type: 'array', cellFormula: true, cellStyles: true });
        const result = formatData(workbook, reportName, reportSize);
        const keys = Object.keys(result[0]?.rows);
        const columns = Object.keys(result[0]?.rows[0].cells);
        const spreadsheet = new Spreadsheet(spreadSheetContainer.current as HTMLDivElement, {
          mode: isEdit ? 'edit' : 'read',
          showToolbar: isEdit,
          showBottomBar: isMultiSheet,
          showContextmenu: false,
          row: {
            len: keys.length >= 100 ? keys.length : 100,
            height: reportSizeFormat(reportName)?.rowHeight
          },
          col: {
            len: columns.length >= 26 ? columns.length : 26,
            width: 0,
            indexWidth: 60,
            minWidth: 60
          },
          view: {
            height: () => document.documentElement.clientHeight - 200 || 400,
            width: () => document.documentElement.clientWidth - 100 || 500
          }
        });
        spreadsheet.loadData(result);
      } catch (e) {
        setLoading(true);
        setIsError(true);
        return;
      }
      setLoading(true);
    })();
  }, [data, isEdit, isMultiSheet, colWidth, reportName, reportSize]);
  return (
    <>
      {
        // Show spreadsheet after loading is complete
        <div ref={spreadSheetContainer} className={className}>
          {!loading && <div>Loading.....</div>}
          {isError && <div>{APPCONSTANTS.REPORT_NOT_FOUND}</div>}
        </div>
      }
    </>
  );
};

export default SpreadsheetViewer;
