import lottie from 'lottie-web';
import { useEffect } from 'react';

import mdtLogo from '../../assets/lottie/logo.json';
import styles from './Loader.module.scss';

interface IStateProps {
  isFullScreen?: boolean;
  className?: string;
}

const Loader = ({ isFullScreen = true, className = '' }: IStateProps) => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#mdt-logo') as Element,
      animationData: mdtLogo,
      renderer: 'svg', // "canvas", "html"
      loop: true, // boolean
      autoplay: true // boolean
    });
  }, []);
  return (
    <div className={`${styles.loaderBackdrop} ${isFullScreen ? styles.fullScreen : ''}`}>
      <div className={`${className}`}>
        <div id='mdt-logo' style={{ width: 75, height: 75 }} />
      </div>
    </div>
  );
};

export default Loader;
