import ReactDOM from 'react-dom';
import { Form } from 'react-final-form';
import React from 'react';
import { FormApi } from 'final-form';

import close from '../../assets/images/close.svg';

import styles from './ModalForm.module.scss';

export interface IModalProps {
  title: string;
  cancelText?: string;
  submitText?: string;
  children?: React.ReactElement;
  show: boolean;
  handleCancel?: () => void;
  deactivateLabel?: string;
  handleFormSubmit: (values?: any) => void;
  handleDeactivate?: () => void;
  render?: (form?: FormApi<any>) => React.ReactElement;
  mutators?: object;
  initialValues?: object;
  size?: 'modal-md' | 'modal-lg' | 'modal-full';
  isDeactivateModal?: boolean;
  isAlertModal?: boolean;
  hasFooter?: boolean;
  autoHeight?: boolean;
  isDisabled?: boolean;
  isCancelButtonNeeded?: boolean;
  isScrollNeeded?: boolean;
}

const ModalForm = React.memo(
  ({
    children,
    show,
    handleCancel,
    title,
    cancelText,
    submitText,
    deactivateLabel,
    handleFormSubmit,
    handleDeactivate,
    initialValues = {},
    size = 'modal-lg',
    isDeactivateModal = false,
    isAlertModal = false,
    hasFooter = true,
    render,
    mutators,
    autoHeight = false,
    isDisabled,
    isCancelButtonNeeded = true,
    isScrollNeeded = false
  }: IModalProps) => {
    if (!show) {
      return null;
    }
    return ReactDOM.createPortal(
      <div className={`${styles.modal} modal modal-show`}>
        <div className={`modal-dialog modal-dialog-centered ${size === 'modal-full' ? styles.modalFull : size}`}>
          <div className={`modal-content ${styles.modalContent} ${autoHeight || styles.height90vh}`}>
            {!isAlertModal && (
              <div className='modal-header py-1 px-1dot25 justify-content-between'>
                <h5 className={`modal-title ${styles.modalTitle}`}>{title}</h5>
                <div
                  className={`d-flex justify-content-center align-items-center ${styles.closeIcon}`}
                  onClick={handleCancel}
                >
                  <img src={close} alt='close' />
                </div>
              </div>
            )}
            <Form
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              mutators={{ ...mutators }}
              render={({ handleSubmit, form }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={`modal-body px-1dot25 py-1dot5 ${isScrollNeeded ? styles.modalScroll : ''}`}>
                      {render ? render(form) : children}
                    </div>
                    <div className={`modal-footer py-dot75 px-1dot25 ${!hasFooter ? styles.hide : ''}`}>
                      {deactivateLabel ? (
                        <button
                          type='button'
                          className='btn danger-btn me-auto'
                          data-dismiss='modal'
                          onClick={handleDeactivate}
                        >
                          {deactivateLabel}
                        </button>
                      ) : null}
                      {isCancelButtonNeeded && (
                        <button
                          type='button'
                          className='btn secondary-btn me-dot5'
                          data-dismiss='modal'
                          onClick={handleCancel}
                        >
                          {cancelText}
                        </button>
                      )}
                      <button
                        disabled={isDisabled}
                        type='submit'
                        className={`btn primary-btn ${isDisabled && 'disabled'}`}
                      >
                        {submitText}
                      </button>
                    </div>
                  </form>
                );
              }}
            />
          </div>
        </div>
      </div>,
      document.body
    );
  }
);
export default ModalForm;
