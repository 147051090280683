import { IUser } from '../store/user/types';

export const PROTECTED_ROUTES = {
  regionDashboard: '/region',
  site: '/region/:regionId/:regionName/site',
  siteByRegion: '/region/:regionId/:regionName',
  reportList: '/region/:regionId/:regionName/site/:siteId/:siteName',
  HFReport: '/region/:regionId/:regionName/site/:siteId/:siteName/:reportType',
  manageUserRoles: '/manage-user-roles',
  home: '/home',
  quickSightDashboard: '/dashboard',
  spiceQuickSightDashboard: '/dashboard/spice-quicksight',
  tcQuickSightDashboard: '/dashboard/telecounselling-quicksight'
};

export const getHomeUrlByRole = (role: string, userData: IUser) => {
  const {country: { id: regionId, name: regionName }} = userData;
  return `${PROTECTED_ROUTES.siteByRegion?.replace(':regionId', regionId).replace(':regionName', regionName)}`;
};
